export const environment = {
  production: false,
  url: 'https://dev.v2.toteim.com',
  api_url: 'https://apidev.v2.toteim.com/occupant',
  locales: [
    'en-US',
    'fr-FR'
  ],
  langs: [
    'en',
    'fr'
  ],
  document_types: ['justificatif', 'edl', 'rib', 'dpe', 'crep', 'gas', 'electricity', 'ernmt', 'insurance', 'anah', 'grid', 'rule', 'furniture', 'diagnostic_other', 'justificatif_other', 'other'],
  version: '406d08a1f764ae82935daa4caec78afd614f14c4'
};
