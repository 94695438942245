import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import * as i0 from '@angular/core';
import { Injectable, signal, EventEmitter, Input, Output, Component, ContentChildren, Pipe } from '@angular/core';
import * as i3 from '@angular/router';
import * as i1$1 from 'primeng/api';
import * as i2 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import * as i1$3 from '@angular/common';
import { CommonModule, formatNumber, formatDate } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as i1$2 from 'primeng/button';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import * as i2$1 from 'primeng/stepper';
import { StepperModule } from 'primeng/stepper';
const _c0 = ["*"];
function ModalComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5)(1, "button", 6);
    i0.ɵɵlistener("click", function ModalComponent_ng_template_1_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.close());
    });
    i0.ɵɵelement(2, "fa-icon", 7);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("icon", ctx_r2.faTimes);
  }
}
function ButtonComponent_Conditional_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 4);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("translate", ctx_r1.label);
  }
}
function ButtonComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 3);
    i0.ɵɵlistener("onClick", function ButtonComponent_Conditional_0_Template_p_button_onClick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleClick($event));
    });
    i0.ɵɵtemplate(1, ButtonComponent_Conditional_0_ng_template_1_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("type", ctx_r1.type)("styleClass", ctx_r1.styleClass)("disabled", ctx_r1.disabled)("severity", ctx_r1.severity)("size", ctx_r1.size)("loading", ctx_r1.loading)("variant", ctx_r1.variant)("rounded", ctx_r1.rounded);
  }
}
function ButtonComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "p-button", 5);
    i0.ɵɵlistener("onClick", function ButtonComponent_Conditional_1_Template_p_button_onClick_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleClick($event));
    });
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("type", ctx_r1.type)("severity", ctx_r1.severity)("styleClass", ctx_r1.styleClass)("disabled", ctx_r1.disabled)("size", ctx_r1.size)("loading", ctx_r1.loading)("variant", ctx_r1.variant)("rounded", ctx_r1.rounded);
  }
}
const _c1 = ["step"];
const _c2 = a0 => ({
  navigateTo: a0
});
function StepperComponent_Conditional_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "p-step", 3);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const i_r1 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("value", i_r1 + 1);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.stepLabels[i_r1] || "Step " + (i_r1 + 1), " ");
  }
}
function StepperComponent_Conditional_0_ng_container_4_ng_template_2_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function StepperComponent_Conditional_0_ng_container_4_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, StepperComponent_Conditional_0_ng_container_4_ng_template_2_ng_container_0_Template, 1, 0, "ng-container", 4);
  }
  if (rf & 2) {
    const step_r3 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", step_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c2, ctx_r1.navigateTo.bind(ctx_r1)));
  }
}
function StepperComponent_Conditional_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "p-step-panel", 3);
    i0.ɵɵtemplate(2, StepperComponent_Conditional_0_ng_container_4_ng_template_2_Template, 1, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const i_r4 = ctx.index;
    i0.ɵɵadvance();
    i0.ɵɵproperty("value", i_r4 + 1);
  }
}
function StepperComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p-stepper", 1)(1, "p-step-list");
    i0.ɵɵtemplate(2, StepperComponent_Conditional_0_ng_container_2_Template, 3, 2, "ng-container", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p-step-panels");
    i0.ɵɵtemplate(4, StepperComponent_Conditional_0_ng_container_4_Template, 4, 1, "ng-container", 2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r1.activeStep);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.steps);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.steps);
  }
}
function StepperComponent_Conditional_1_For_2_ng_template_4_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function StepperComponent_Conditional_1_For_2_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, StepperComponent_Conditional_1_For_2_ng_template_4_ng_container_0_Template, 1, 0, "ng-container", 4);
  }
  if (rf & 2) {
    const step_r5 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", step_r5)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c2, ctx_r1.navigateTo.bind(ctx_r1)));
  }
}
function StepperComponent_Conditional_1_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p-step-item", 3)(1, "p-step");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p-step-panel", 3);
    i0.ɵɵtemplate(4, StepperComponent_Conditional_1_For_2_ng_template_4_Template, 1, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ɵ$index_30_r6 = ctx.$index;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("value", ɵ$index_30_r6 + 1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.stepLabels[ɵ$index_30_r6] || "Step " + (ɵ$index_30_r6 + 1), " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("value", ɵ$index_30_r6 + 1);
  }
}
function StepperComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p-stepper", 1);
    i0.ɵɵrepeaterCreate(1, StepperComponent_Conditional_1_For_2_Template, 6, 3, "p-step-item", 3, i0.ɵɵrepeaterTrackByIdentity);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r1.activeStep);
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r1.steps);
  }
}
class AuthService {
  redirect_url = '/';
  token = localStorage.getItem('api_token');
  localStorageList = [];
  constructor() {}
  isLoggedIn() {
    if (this.token) {
      try {
        let jwt = JSON.parse(atob(this.token.split('.')[1]));
        let date = Math.floor(Date.now() / 1000);
        return date < jwt.exp;
      } catch (e) {
        return false;
      }
    }
    return false;
  }
  setLocalStorageList(localStorageList) {
    this.localStorageList = localStorageList;
  }
  setToken(token) {
    this.token = token;
    localStorage.setItem('api_token', token);
  }
  getToken() {
    return this.token || '';
  }
  setRedirectUrl(url) {
    this.redirect_url = url;
  }
  getRedirectUrl() {
    return this.redirect_url;
  }
  logout() {
    this.token = null;
    localStorage.removeItem('api_token');
    for (let item of this.localStorageList) {
      localStorage.removeItem(item);
    }
  }
  static ɵfac = function AuthService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthService,
    factory: AuthService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class ApiService {
  http;
  authService;
  router;
  base_url = "";
  version = "";
  identifying_id = "";
  home_url = "";
  login_url = "";
  constructor(http, authService, router) {
    this.http = http;
    this.authService = authService;
    this.router = router;
  }
  initialize(api_url, version, identifying_id, home_url, login_url = "/login") {
    this.base_url = api_url;
    this.version = version;
    this.identifying_id = identifying_id;
    this.home_url = home_url;
    this.login_url = login_url;
  }
  post(service, params, success, fail) {
    let headers = {};
    headers['X-Url'] = location.toString();
    headers['X-Source'] = 'web';
    headers['X-Version'] = this.version;
    if (this.authService.isLoggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authService.getToken();
    }
    if (!params) {
      params = {};
    }
    if (this.identifying_id.length > 0 && !params[this.identifying_id]) {
      params[this.identifying_id] = localStorage.getItem(this.identifying_id);
    }
    this.http.post(this.getUrl(service), params, {
      headers: new HttpHeaders(headers)
    }).subscribe(res => {
      if (res?.success) {
        if (success) success(res);
      } else {
        if (fail) fail(res);
        if (res.error === 401) {
          this.authService.logout();
          this.router.navigate([this.login_url]);
        }
      }
    }, err => {
      let res = err?.error || {};
      if (res?.success) {
        if (success) success(res);
      } else {
        if (fail) fail(res);
        if (res?.error === 401) {
          this.authService.logout();
          this.router.navigate([this.login_url]);
        }
      }
    });
  }
  upload(files, filename = '', filetype = 'document', progress = null, success = null, fail = null) {
    let formData = new FormData();
    if (files instanceof FileList) {
      for (let i = 0; i < files.length; i++) {
        formData.append('file' + i, files.item(i), files.item(i).name);
      }
    } else if (files instanceof File) {
      formData.append('files[]', files, files.name);
    } else if (files instanceof Blob) {
      formData.append('files[]', files, filename);
    }
    let headers = {};
    headers['X-Url'] = location.toString();
    headers['X-Source'] = 'web';
    headers['X-Version'] = this.version;
    this.post('document/upload-link', {
      type: filetype
    }, res => {
      this.http.request(new HttpRequest('POST', res.data, formData, {
        reportProgress: true,
        headers: new HttpHeaders(headers)
      })).subscribe(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            if (progress) progress(0);
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round(100 * event.loaded / event.total);
            if (progress) progress(percentDone);
            break;
          case HttpEventType.Response:
            let res = event?.body || {};
            if (res.success) {
              if (success) success(res);
            } else {
              if (fail) fail(res);
            }
            break;
        }
      }, err => {
        let res = err?.error || {};
        if (res.success) {
          if (success) success(res);
        } else {
          if (fail) fail(res);
        }
      });
    }, res => {
      if (fail) fail(res);
    });
  }
  getHomeUrl() {
    return this.home_url;
  }
  getLoginUrl() {
    return this.login_url;
  }
  logout(callback) {
    this.post('logout', {}, _ => {
      this.authService.logout();
      callback();
    }, _ => {
      this.authService.logout();
      callback();
    });
  }
  getUrl(service) {
    if (service[0] == '/') {
      return this.base_url + service;
    }
    return this.base_url + '/' + service;
  }
  static ɵfac = function ApiService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ApiService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(AuthService), i0.ɵɵinject(i3.Router));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApiService,
    factory: ApiService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: AuthService
  }, {
    type: i3.Router
  }], null);
})();
class I18nService {
  locale = '';
  locales = ['en-US', 'en-GB', 'fr-FR'];
  constructor() {
    this.setLocale(localStorage.getItem('locale') || this.normalizeLocale(navigator.language));
  }
  setLocale(locale) {
    if (!this.locales.includes(locale)) {
      this.locale = locale.substring(0, 2) === 'en' ? 'en-US' : 'fr-FR';
    } else {
      this.locale = locale;
    }
    localStorage.setItem('locale', this.locale);
    document.querySelector('html').lang = this.getLang();
  }
  getLocale() {
    return this.locale;
  }
  getLang() {
    return this.locale.substring(0, 2);
  }
  normalizeLocale(locale) {
    if (locale.length <= 0 || locale === 'C' || locale === 'posix' || locale === 'POSIX') {
      return 'fr-FR';
    }
    if (locale.indexOf('.') !== -1) {
      const actualLocale = locale.split('.')[0];
      return this.normalizeLocale(actualLocale);
    }
    if (locale.indexOf('@') !== -1) {
      const actualLocale = locale.split('@')[0];
      return this.normalizeLocale(actualLocale);
    }
    if (locale.indexOf('-') !== -1) {
      const [splitEl1 = '', splitEl2 = ''] = locale.split('-');
      return `${splitEl1}-${splitEl2.toUpperCase()}`;
    }
    return `${locale}-${locale.toUpperCase()}`;
  }
  static ɵfac = function I18nService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || I18nService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: I18nService,
    factory: I18nService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(I18nService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class ThemeService {
  theme = signal('light');
  constructor() {
    this.setTheme(localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'));
  }
  toggleTheme() {
    this.setTheme(this.theme() === 'dark' ? 'light' : 'dark');
  }
  setTheme(theme) {
    this.theme.set(theme);
    if (this.theme() === 'dark') {
      document.querySelector('html')?.classList.add('app-dark');
    } else {
      document.querySelector('html')?.classList.remove('app-dark');
    }
    localStorage.setItem('theme', theme);
  }
  getTheme() {
    return this.theme;
  }
  static ɵfac = function ThemeService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ThemeService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ThemeService,
    factory: ThemeService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class ToastService {
  messageService;
  translate;
  constructor(messageService, translate) {
    this.messageService = messageService;
    this.translate = translate;
  }
  showSuccess(message) {
    this.translate.get([message, "toast.success"]).subscribe(res => {
      this.messageService.add({
        severity: 'success',
        summary: res["toast.success"],
        detail: res[message]
      });
    });
  }
  showError(message) {
    this.translate.get([message || 'error.default', "toast.error"]).subscribe(res => {
      this.messageService.add({
        severity: 'error',
        summary: res["toast.error"],
        detail: res[message || 'error.default']
      });
    });
  }
  showInfo(message) {
    this.translate.get([message, "toast.info"]).subscribe(res => {
      this.messageService.add({
        severity: 'info',
        summary: res["toast.info"],
        detail: res[message]
      });
    });
  }
  showWarn(message) {
    this.translate.get([message, "toast.warn"]).subscribe(res => {
      this.messageService.add({
        severity: 'warn',
        summary: res["toast.warn"],
        detail: res[message]
      });
    });
  }
  static ɵfac = function ToastService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ToastService)(i0.ɵɵinject(i1$1.MessageService), i0.ɵɵinject(i2.TranslateService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ToastService,
    factory: ToastService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.MessageService
  }, {
    type: i2.TranslateService
  }], null);
})();
class ModalComponent {
  visible = false;
  headerText = 'test';
  width = '50vw';
  onClose = new EventEmitter();
  size = 'lg'; // Taille de la modal
  get sizeClass() {
    return {
      sm: 'modal-sm',
      md: 'modal-md',
      lg: 'modal-lg',
      xl: 'modal-xl'
    }[this.size] || 'modal-md';
  }
  open() {
    this.visible = true;
  }
  close() {
    this.visible = false;
    this.onClose.emit();
  }
  faTimes = faTimes;
  static ɵfac = function ModalComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ModalComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ModalComponent,
    selectors: [["t-modal"]],
    inputs: {
      headerText: "headerText",
      width: "width",
      size: "size"
    },
    outputs: {
      onClose: "onClose"
    },
    ngContentSelectors: _c0,
    decls: 7,
    vars: 7,
    consts: [["header", ""], [3, "visibleChange", "onHide", "visible", "modal", "draggable", "resizable", "closable", "styleClass"], [1, "custom-modal-title"], [3, "translate"], [1, "custom-modal-content"], [1, "custom-modal-header"], [1, "icon-circle", 3, "click"], [3, "icon"]],
    template: function ModalComponent_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "p-dialog", 1);
        i0.ɵɵtwoWayListener("visibleChange", function ModalComponent_Template_p_dialog_visibleChange_0_listener($event) {
          i0.ɵɵrestoreView(_r1);
          i0.ɵɵtwoWayBindingSet(ctx.visible, $event) || (ctx.visible = $event);
          return i0.ɵɵresetView($event);
        });
        i0.ɵɵlistener("onHide", function ModalComponent_Template_p_dialog_onHide_0_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.close());
        });
        i0.ɵɵtemplate(1, ModalComponent_ng_template_1_Template, 3, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵelement(4, "h3", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 4);
        i0.ɵɵprojection(6);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵtwoWayProperty("visible", ctx.visible);
        i0.ɵɵproperty("modal", true)("draggable", false)("resizable", false)("closable", false)("styleClass", ctx.sizeClass);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("translate", ctx.headerText);
      }
    },
    dependencies: [CommonModule, FaIconComponent, Dialog],
    styles: [".icon-circle[_ngcontent-%COMP%]{margin:0!important;padding:0!important;display:inline-flex;align-items:center;justify-content:center;width:25px;height:25px;border-radius:50%;background-color:#fff;color:#1895cf;font-size:20px;border:0;float:right}.icon-circle[_ngcontent-%COMP%]:hover{background-color:#1895cf;color:#fff}.custom-modal-header[_ngcontent-%COMP%]{background:#1895cf;color:#fff;padding:4px;width:100%}.custom-modal-title[_ngcontent-%COMP%]{padding-top:15px;flex-grow:1;text-align:center;font-family:Comfortaa,sans-serif;color:#1895cf;margin-right:10px}.close-button[_ngcontent-%COMP%]{float:right;cursor:pointer;position:absolute;right:10px;font-size:1em;transform:translateY(-50%);color:#1895cf;border:1px solid #1895cf;border-radius:90%;background-color:#fff}.close-button[_ngcontent-%COMP%]:hover{color:#d9534f}.custom-modal-content[_ngcontent-%COMP%]{padding:15px}[_nghost-%COMP%]     .modal-md{width:50vw;max-width:600px}[_nghost-%COMP%]     .modal-sm{width:30vw;max-width:400px}[_nghost-%COMP%]     .modal-lg{width:70vw;max-width:900px}[_nghost-%COMP%]     .modal-xl{width:90vw;max-width:1200px}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalComponent, [{
    type: Component,
    args: [{
      selector: 't-modal',
      imports: [CommonModule, FaIconComponent, Dialog],
      template: "<p-dialog\n  [(visible)]=\"visible\"\n  [modal]=\"true\"\n  (onHide)=\"close()\"\n  [draggable]=\"false\"\n  [resizable]=\"false\"\n  [closable]=\"false\"\n  [styleClass]=\"sizeClass\">\n  <ng-template #header>\n    <div class=\"custom-modal-header\">\n      <button class=\"icon-circle\" (click)=\"close()\"><fa-icon [icon]=\"faTimes\"></fa-icon></button>\n    </div>\n  </ng-template>\n  <div class=\"custom-modal-title\">\n    <h3 [translate]=\"headerText\"></h3>\n  </div>\n  <div class=\"custom-modal-content\">\n    <ng-content></ng-content>\n  </div>\n</p-dialog>\n",
      styles: [".icon-circle{margin:0!important;padding:0!important;display:inline-flex;align-items:center;justify-content:center;width:25px;height:25px;border-radius:50%;background-color:#fff;color:#1895cf;font-size:20px;border:0;float:right}.icon-circle:hover{background-color:#1895cf;color:#fff}.custom-modal-header{background:#1895cf;color:#fff;padding:4px;width:100%}.custom-modal-title{padding-top:15px;flex-grow:1;text-align:center;font-family:Comfortaa,sans-serif;color:#1895cf;margin-right:10px}.close-button{float:right;cursor:pointer;position:absolute;right:10px;font-size:1em;transform:translateY(-50%);color:#1895cf;border:1px solid #1895cf;border-radius:90%;background-color:#fff}.close-button:hover{color:#d9534f}.custom-modal-content{padding:15px}:host ::ng-deep .modal-md{width:50vw;max-width:600px}:host ::ng-deep .modal-sm{width:30vw;max-width:400px}:host ::ng-deep .modal-lg{width:70vw;max-width:900px}:host ::ng-deep .modal-xl{width:90vw;max-width:1200px}\n"]
    }]
  }], null, {
    headerText: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    onClose: [{
      type: Output
    }],
    size: [{
      type: Input
    }]
  });
})();
class ButtonComponent {
  label = "";
  severity = 'primary';
  styleClass;
  size;
  type = 'button';
  disabled = false;
  loading = false;
  rounded = false;
  variant = null;
  onClick = new EventEmitter();
  handleClick(event) {
    this.onClick.emit(event);
  }
  static ɵfac = function ButtonComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ButtonComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ButtonComponent,
    selectors: [["t-button"]],
    inputs: {
      label: "label",
      severity: "severity",
      styleClass: "styleClass",
      size: "size",
      type: "type",
      disabled: "disabled",
      loading: "loading",
      rounded: "rounded",
      variant: "variant"
    },
    outputs: {
      onClick: "onClick"
    },
    ngContentSelectors: _c0,
    decls: 2,
    vars: 1,
    consts: [["content", ""], [3, "type", "styleClass", "disabled", "severity", "size", "loading", "variant", "rounded"], [3, "type", "severity", "styleClass", "disabled", "size", "loading", "variant", "rounded"], [3, "onClick", "type", "styleClass", "disabled", "severity", "size", "loading", "variant", "rounded"], [3, "translate"], [3, "onClick", "type", "severity", "styleClass", "disabled", "size", "loading", "variant", "rounded"]],
    template: function ButtonComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵtemplate(0, ButtonComponent_Conditional_0_Template, 3, 8, "p-button", 1)(1, ButtonComponent_Conditional_1_Template, 2, 8, "p-button", 2);
      }
      if (rf & 2) {
        i0.ɵɵconditional(ctx.label != "" ? 0 : 1);
      }
    },
    dependencies: [ButtonModule, i1$2.Button, TranslateModule, i2.TranslateDirective, ProgressSpinnerModule],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonComponent, [{
    type: Component,
    args: [{
      selector: 't-button',
      imports: [ButtonModule, TranslateModule, ProgressSpinnerModule],
      template: "@if (label != \"\") {\n  <p-button\n    [type]=\"type\"\n    [styleClass]=\"styleClass\"\n    [disabled]=\"disabled\"\n    [severity]=\"severity\"\n    [size]=\"size\"\n    [loading]=\"loading\"\n    [variant]=\"variant\"\n    [rounded]=\"rounded\"\n    (onClick)=\"handleClick($event)\">\n      <ng-template #content>\n        <span [translate]=\"label\"></span>\n      </ng-template>\n  </p-button>\n} @else {\n  <p-button\n    [type]=\"type\"\n    [severity]=\"severity\"\n    [styleClass]=\"styleClass\"\n    [disabled]=\"disabled\"\n    [size]=\"size\"\n    [loading]=\"loading\"\n    [variant]=\"variant\"\n    [rounded]=\"rounded\"\n    (onClick)=\"handleClick($event)\">\n      <ng-content></ng-content>\n  </p-button>\n}\n"
    }]
  }], null, {
    label: [{
      type: Input
    }],
    severity: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    rounded: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }]
  });
})();
class StepperComponent {
  activeStep = 1;
  orientation = 'horizontal';
  stepLabels = [];
  steps;
  navigateTo(step) {
    if (step > 0 && step <= this.steps.length) {
      this.activeStep = step;
    }
  }
  static ɵfac = function StepperComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || StepperComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: StepperComponent,
    selectors: [["t-stepper"]],
    contentQueries: function StepperComponent_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, _c1, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.steps = _t);
      }
    },
    inputs: {
      activeStep: "activeStep",
      orientation: "orientation",
      stepLabels: "stepLabels"
    },
    decls: 2,
    vars: 1,
    consts: [["content", ""], [1, "basis-[50rem]", 3, "value"], [4, "ngFor", "ngForOf"], [3, "value"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"]],
    template: function StepperComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, StepperComponent_Conditional_0_Template, 5, 3, "p-stepper", 1)(1, StepperComponent_Conditional_1_Template, 3, 1, "p-stepper", 1);
      }
      if (rf & 2) {
        i0.ɵɵconditional(ctx.orientation == "horizontal" ? 0 : 1);
      }
    },
    dependencies: [CommonModule, i1$3.NgForOf, i1$3.NgTemplateOutlet, StepperModule, i2$1.Stepper, i2$1.StepList, i2$1.StepPanels, i2$1.StepPanel, i2$1.StepItem, i2$1.Step, ButtonModule],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperComponent, [{
    type: Component,
    args: [{
      selector: 't-stepper',
      imports: [CommonModule, StepperModule, ButtonModule],
      template: "@if (orientation == \"horizontal\") {\n  <p-stepper\n    [value]=\"activeStep\"\n    class=\"basis-[50rem]\"\n  >\n    <p-step-list>\n      <ng-container *ngFor=\"let step of steps; let i = index\">\n        <p-step [value]=\"i + 1\">\n          {{ stepLabels[i] || 'Step ' + (i + 1) }}\n        </p-step>\n      </ng-container>\n    </p-step-list>\n\n    <p-step-panels>\n      <ng-container *ngFor=\"let step of steps; let i = index\">\n        <p-step-panel [value]=\"i + 1\">\n          <ng-template #content>\n            <ng-container *ngTemplateOutlet=\"step; context: { navigateTo: navigateTo.bind(this) }\"></ng-container>\n          </ng-template>\n        </p-step-panel>\n      </ng-container>\n    </p-step-panels>\n  </p-stepper>\n} @else {\n  <p-stepper\n    [value]=\"activeStep\"\n    class=\"basis-[50rem]\"\n  >\n    @for (step of steps; track step; let i = $index) {\n      <p-step-item [value]=\"i + 1\">\n        <p-step>\n          {{ stepLabels[i] || 'Step ' + (i + 1) }}\n        </p-step>\n        <p-step-panel [value]=\"i + 1\">\n          <ng-template #content>\n            <ng-container *ngTemplateOutlet=\"step; context: { navigateTo: navigateTo.bind(this) }\"></ng-container>\n          </ng-template>\n        </p-step-panel>\n      </p-step-item>\n\n    }\n  </p-stepper>\n}\n"
    }]
  }], null, {
    activeStep: [{
      type: Input
    }],
    orientation: [{
      type: Input
    }],
    stepLabels: [{
      type: Input
    }],
    steps: [{
      type: ContentChildren,
      args: ['step']
    }]
  });
})();
class AuthGuard {
  authService;
  apiService;
  router;
  constructor(authService, apiService, router) {
    this.authService = authService;
    this.apiService = apiService;
    this.router = router;
  }
  canActivate(next, state) {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    this.authService.setRedirectUrl(state.url);
    this.router.navigate([this.apiService.getLoginUrl()]);
    return false;
  }
  static ɵfac = function AuthGuard_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthGuard)(i0.ɵɵinject(AuthService), i0.ɵɵinject(ApiService), i0.ɵɵinject(i3.Router));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthGuard,
    factory: AuthGuard.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AuthService
  }, {
    type: ApiService
  }, {
    type: i3.Router
  }], null);
})();
class GuestGuard {
  authService;
  apiService;
  router;
  constructor(authService, apiService, router) {
    this.authService = authService;
    this.apiService = apiService;
    this.router = router;
  }
  canActivate(next, state) {
    if (!this.authService.isLoggedIn()) {
      return true;
    }
    this.router.navigate([this.apiService.getHomeUrl()]);
    return false;
  }
  static ɵfac = function GuestGuard_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GuestGuard)(i0.ɵɵinject(AuthService), i0.ɵɵinject(ApiService), i0.ɵɵinject(i3.Router));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: GuestGuard,
    factory: GuestGuard.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GuestGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AuthService
  }, {
    type: ApiService
  }, {
    type: i3.Router
  }], null);
})();
class NumberPipe {
  i18nService;
  constructor(i18nService) {
    this.i18nService = i18nService;
  }
  transform(value, digitsInfo, locale) {
    if (!locale) {
      locale = this.i18nService.getLocale();
    }
    return formatNumber(+value, locale, digitsInfo);
  }
  static ɵfac = function NumberPipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NumberPipe)(i0.ɵɵdirectiveInject(I18nService, 16));
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "number",
    type: NumberPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberPipe, [{
    type: Pipe,
    args: [{
      name: 'number',
      standalone: true
    }]
  }], () => [{
    type: I18nService
  }], null);
})();
class DatePipe {
  i18nService;
  constructor(i18nService) {
    this.i18nService = i18nService;
  }
  transform(value, format, timezone, locale) {
    if (!locale) {
      locale = this.i18nService.getLocale();
    }
    return formatDate(value, format, locale, timezone);
  }
  static ɵfac = function DatePipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DatePipe)(i0.ɵɵdirectiveInject(I18nService, 16));
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "date",
    type: DatePipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePipe, [{
    type: Pipe,
    args: [{
      name: 'date',
      standalone: true
    }]
  }], () => [{
    type: I18nService
  }], null);
})();

/*
 * Public API Surface of angular-lib
 */
/*
 * Services
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ApiService, AuthGuard, AuthService, ButtonComponent, DatePipe, GuestGuard, I18nService, ModalComponent, NumberPipe, StepperComponent, ThemeService, ToastService };
